import React from 'react';
import TabbedPage from '../TabbedPage/tabbedPage';
import CashOuts from './cash-outs';
import CashOutsProvider from '../../providers/Transactions/cashoutProvider';
import Purchases from './purchases';
import PurchasesProvider from '../../providers/Transactions/purchaseProvider';
import TopClientsMonthlyConsumptionProvider from '../../providers/Transactions/topClientsMonthlyConsumptionProvider';
import TopClients from './top-clients';

const tabs = [
  {
    title: 'Credit Purchases',
    id: 'credit-purchases',
    component: () => {
      return <PurchasesProvider.Provider><Purchases /></PurchasesProvider.Provider>
    },
  },
  {
    title: 'Cashouts',
    id: 'cash-outs',
    component: () => {
      return <CashOutsProvider.Provider><CashOuts /></CashOutsProvider.Provider>
    },
  },
  {
    title: 'Top Clients',
    id: 'top-clients',
    component: () => {
      return <TopClientsMonthlyConsumptionProvider.Provider><TopClients /></TopClientsMonthlyConsumptionProvider.Provider>
    },
  }
];

export default () => {
  return (
    <div>
      <h1 className="main-pane-header">Transactions</h1>
      <TabbedPage tabs={tabs} defaultTab="credit-purchases" />
    </div>
  );
}
