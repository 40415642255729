import { AdvisorApplicationsState } from './advisorApplicationsActions';
import AdvisorApplicationsViewModel from '../../viewModels/AdvisorApplicationsViewModel';
import { AdvisorApplicationModel } from '../../api/AskAGirl';
import updateCache from '../../utilities/updateCache';

const cache = updateCache.bind(null, AdvisorApplicationsViewModel);

export default function (state: AdvisorApplicationsState, action: any): AdvisorApplicationsState {
  const newState = { ...state };
  let cached;
  switch (action.type) {
    case 'fetchForFirstLoad':
      newState.rows = action.rows.map((m: AdvisorApplicationModel) => cache(newState.cached, m));
      break;
    case 'startFetching':
      newState.isFetching = true;
      break;
    case 'stopFetching':
      newState.isFetching = false;
      break;
    case 'approved':
      cached = newState.cached[action.row_id];
      if (null != cached) {
        cached.acceptedInSession = true;
      }
      break;
    case 'denied':
      cached = newState.cached[action.row_id];
      if (null != cached) {
        cached.deniedInSession = true;
      }
      break;
  }
  return newState;
}
