import { AdvisorApplicationModel } from '../api/AskAGirl';

export default class FeedbackViewModel {

  model: AdvisorApplicationModel;

  public acceptedInSession = false;
  public deniedInSession = false;

  constructor(model: AdvisorApplicationModel) {
    this.model = model;
  }

  // MARK: default

  get govIdFront(): string { return this.model.government_id_front_image_url }
  get govIdBack(): string { return this.model.government_id_back_image_url }
  get profileImageUrls(): string[] { return this.model.profile_image_urls }
  get aboutMe(): string { return this.model.about_me }
  get expertise(): string { return this.model.expertise.join(', ') }

  get created(): Date { return new Date(this.model.created_at) }

  get user_id(): string { return this.model.user_id.toString() };
  get id(): string { return this.model.id.toString() };

  setModel(model: AdvisorApplicationModel) {
    this.model = model;
  }

}
