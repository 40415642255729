import React, { useContext, useEffect, useState } from 'react';
import CashOutProvider from '../../providers/Transactions/cashoutProvider';
import UsersProvider from '../../providers/Users/usersProvider';
import ConfigableTable from '../ConfigableTable/configableTable';
import combine from '../../utilities/combine';

const columns = {
  key: 'id',
  Member: 'nameLink',
  Amount: 'amount',
  Status: 'status',
  Created: 'createdAsString',
  'Succeeded/Failed': 'endedAsString',
};

const visibleColumns = [
  'Member', 
  'Amount', 
  'Status', 
  'Created', 
  'Succeeded/Failed'
];


export default () => {
  const [search, setSearch] = useState<string | null>(null);
  const cashout = useContext(CashOutProvider.context) as any;
  const users = useContext(UsersProvider.context) as any;
  //eslint-disable-next-line
  useEffect(() => cashout.actions.voidFetchForFirstLoad(users), []);
  //eslint-disable-next-line
  useEffect(() => (search === null) ? undefined : cashout.actions.voidSearch(search, users), [search]);
  const rows = combine(cashout.state.rows, users.state.cached, 'user_id');
  return (
    <div>
      <div className="dashboard main-pane">
        <input className="search"
               placeholder="🕵️  Search Cash Outs"
               onChange={e => setSearch(e.target.value)} />
        <ConfigableTable
          columns={columns}
          visibleColumns={visibleColumns}
          rows={rows}
        />
      </div>
    </div>
  );
}
