import React, { useContext, useEffect } from 'react';
import './top-clients.scss';
import UsersProvider from '../../providers/Users/usersProvider';
import TopClientsConsumptionSummaryProvider from '../../providers/Transactions/topClientsMonthlyConsumptionProvider';
import ConfigableTable from '../ConfigableTable/configableTable';
import combine from '../../utilities/combine';

const columns = {
  key: 'id',
  Member: 'nameLink',
  Email: 'emailLink',
  'Text Messages Sent': 'text_messages',
  'Audio Call Time': 'audio_call_time',
  'Video Call Time': 'video_call_time',
  'Credits Spent': 'credits',
};

const visibleColumns = [
  'Member', 
  'Email',
  'Text Messages Sent',
  'Audio Call Time',
  'Video Call Time',
  'Credits Spent',
];


export default () => {
  const users = useContext(UsersProvider.context) as any;
  const top_clients = useContext(TopClientsConsumptionSummaryProvider.context) as any;
  const csv_rows = [[
    'Year', 
    'Month', 
    'Name', 
    'Email Address',
    'Text Messages Sent',
    'Audio Call Time',
    'Video Call Time',
    'Credits Spent',
  ].join(',')];
  top_clients.state.rows.forEach((row: any) => {
    const sub_rows = combine(row.summaries, users.state.cached, 'user_id');
    sub_rows.forEach((sub_row: any) => {
      csv_rows.push([
        row.year,
        row.month,
        sub_row.name,
        sub_row.email,
        sub_row.model.text_messages,
        sub_row.audio_call_time,
        sub_row.video_call_time,
        sub_row.model.amount_credits,
      ].join(','))
    });
  });
  const csv_file = csv_rows.join('\n');
  const csv_base64 = btoa(csv_file);
  const csv_href = `data:application/text;base64,${csv_base64}`;
  //eslint-disable-next-line
  useEffect(() => top_clients.actions.voidFetchForFirstLoad(users), []);
  const rows = top_clients.state.rows;
  return (
    <div className="top-clients">
      <div className="dashboard main-pane">
        <a href={csv_href} download="top-clients.csv">Download CSV</a>
      </div>
      {rows.map((row: any) => {
        const sub_rows = combine(row.summaries, users.state.cached, 'user_id');
        const has_rows = (0 < sub_rows.length); 
        if (false === has_rows) {
          return <div key={row.id}>No top users yet for {row.month}, {row.year}!</div>;
        }
        return <div key={row.id} className="dashboard main-pane">
          <h3>{row.month}, {row.year}</h3>
          <ConfigableTable 
            key={row.id}
            columns={columns}
            visibleColumns={visibleColumns}
            rows={sub_rows}
          />
        </div>
      })}
    </div>
  );
}
