import React, { useContext, useEffect, useState } from 'react';
import { Route, Link, useRouteMatch } from 'react-router-dom';
import FeedbackProvider from '../../providers/Feedback/feedbackProvider';
import UsersProvider from '../../providers/Users/usersProvider';
import FeedbackViewModel from '../../viewModels/FeedbackViewModel';
import ConfigableTable from '../ConfigableTable/configableTable';
import UserCard from '../UserCard/userCard';
import combine from '../../utilities/combine';
import BanUser from '../UserCard/banUser';

const Popover = () => {
  const match = useRouteMatch();
  const id = match.params.id;
  const feedback = useContext(FeedbackProvider.context) as any;
  const users = useContext(UsersProvider.context) as any;
  //eslint-disable-next-line
  useEffect(() => feedback.actions.voidGetById(id), [id]);
  const model: FeedbackViewModel = feedback.state.cached[id];
  //eslint-disable-next-line
  useEffect(() => users.actions.voidGetById(model ? model.reportedBy : null), [model]);
  //eslint-disable-next-line
  useEffect(() => users.actions.voidGetById(model ? model.user_id : null), [model]);
  if (!model) return null;
  const reporter = users.state.cached[model.reportedBy];
  const user = users.state.cached[model.user_id];
  if (!user) return null;
  const starClass = 'star';//classNames('star', { filled: model.starred });
  return <UserCard type="red" title={user.name} images={user.photos}>
    {model.reported && !user.banned && <div className="report-reason">Reported for {model.reportedReason}</div>}
    {user.banned && <div className="report-reason">Banned until {user.bannedUntilAsString}</div>}
    <div className="email row">
      {user.email}
      <div className={starClass} />
    </div>
    <div className="row">
      <strong>Feedback</strong>
      <br />
      {model.details}
      <div className="reported-by row">
        Recorded by <span>{reporter && reporter.name}</span> on {model.createdAsString}
      </div>
    </div>
    <BanUser user={user} />
  </UserCard>;
};

const Actions = ({ data }: { data: FeedbackViewModel }) => {
  const match = useRouteMatch();
  return <Link to={`${match.path}/actions/${data.id}`}>
    <button className="std-button">View</button>
  </Link>;
};

const columns = {
  Member: 'name',
  Details: 'details',
  Stars: 'rating',
  Reported: 'reportedAsString',
  Date: 'createdAsString',
  key: 'id',
  Actions: Actions
};

const visibleColumns = [
  'Member', 'Details', 'Stars', 'Reported', 'Date', 'Actions'
];


type Props = { source: string };

export default ({ source }: Props) => {
  const [search, setSearch] = useState<string | null>(null);
  const feedback = useContext(FeedbackProvider.context) as any;
  const users = useContext(UsersProvider.context) as any;
  //eslint-disable-next-line
  useEffect(() => feedback.actions.voidFetchForFirstLoad(source, users), []);
  //eslint-disable-next-line
  useEffect(() => (search === null) ? undefined : feedback.actions.voidSearch(search, source, users), [search]);
  const rows = combine(feedback.state.rows, users.state.cached, 'user_id');
  const match = useRouteMatch();
  return (
    <div>
      <div className="dashboard main-pane">
        <input className="search"
               placeholder="🕵️  Search feedback"
               onChange={e => setSearch(e.target.value)} />
        <ConfigableTable
          columns={columns}
          visibleColumns={visibleColumns}
          rows={rows}
        />
      </div>
      <Route path={`${match.path}/actions/:id`} component={Popover} />
    </div>
  );
}
