import React, { useContext, useEffect, useState } from 'react';
import UsersProvider from '../../providers/Users/usersProvider';
import PurchaseProvider from '../../providers/Transactions/purchaseProvider';
import ConfigableTable from '../ConfigableTable/configableTable';
import combine from '../../utilities/combine';

const columns = {
  key: 'id',
  Member: 'nameLink',
  Credits: 'credits',
  Price: 'price',
  Purchased: 'purchasedAtAsString',
};

const visibleColumns = [
  'Member', 
  'Credits',
  'Price',
  'Purchased', 
];


export default () => {
  const [search, setSearch] = useState<string | null>(null);
  const purchase = useContext(PurchaseProvider.context) as any;
  const users = useContext(UsersProvider.context) as any;
  //eslint-disable-next-line
  useEffect(() => purchase.actions.voidFetchForFirstLoad(users), []);
  //eslint-disable-next-line
  useEffect(() => (search === null) ? undefined : purchase.actions.voidSearch(search, users), [search]);
  const rows = combine(purchase.state.rows, users.state.cached, 'user_id');
  return (
    <div>
      <div className="dashboard main-pane">
        <input className="search"
               placeholder="🕵️  Search Purchases"
               onChange={e => setSearch(e.target.value)} />
        <br></br>
        20 Credits: {purchase.state.counts.twenty}
        <br></br>
        40 Credits: {purchase.state.counts.fourty}
        <br></br>
        80 Credits: {purchase.state.counts.eighty}
        <br></br>
        100 Credits: {purchase.state.counts.one_hundred}
        <br></br>
        500 Credits: {purchase.state.counts.five_hundred}
        <br></br>
        1,000 Credits: {purchase.state.counts.one_thousand}
        <br></br>
        Total: {purchase.state.counts.total_sold}
        <br></br>
        <br></br>
        <ConfigableTable
          columns={columns}
          visibleColumns={visibleColumns}
          rows={rows}
        />
      </div>
    </div>
  );
}
