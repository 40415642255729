import { PurchaseCountsModel } from '../api/AskAGirl';

export default class PurchaseViewModel {

  model: PurchaseCountsModel;

  constructor(model: PurchaseCountsModel) {
    this.model = model;
  }

  // MARK: default

  get twenty(): string {
    return this.model['com.askagirl.twenty_credits'].toLocaleString();
  }

  get fourty(): string {
    return this.model['com.askagirl.fourty_credits'].toLocaleString();
  }

  get eighty(): string {
    return this.model['com.askagirl.eighty_credits'].toLocaleString();
  }

  get one_hundred(): string {
    return this.model['com.askagirl.one_hundred_credits'].toLocaleString();
  }

  get five_hundred(): string {
    return this.model['com.askagirl.five_hundred_credits'].toLocaleString();
  }

  get one_thousand(): string {
    return this.model['com.askagirl.one_thousand_credits'].toLocaleString();
  }

  get total_sold(): string {
    const sum = (
      4.99 * (this.model['com.askagirl.twenty_credits']) +
      9.99 * (this.model['com.askagirl.fourty_credits']) +
      19.99 * (this.model['com.askagirl.eighty_credits']) +
      24.99 * (this.model['com.askagirl.one_hundred_credits']) +
      124.99 * (this.model['com.askagirl.five_hundred_credits']) +
      249.99 * (this.model['com.askagirl.one_thousand_credits']) 
    );
    return '$' + sum.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',     
      currencyDisplay: 'code'     
    });
  }

  // MARK: custom

  setModel(model: PurchaseCountsModel) {
    this.model = model;
  }

}
