import AskAGirl from '../../api/AskAGirl';
import CashOutViewModel from '../../viewModels/CashOutViewModel';

export interface CashOutState {
  rows: CashOutViewModel[],
  cached: { [id: string]: CashOutViewModel }
};

export default {

    initState: () => {
      return {
        cached: {},
        rows: []
      };
    },

    fetchForFirstLoad: async (userContext: any) => {
      const { cash_outs, users } = await AskAGirl.getCashOutsBefore(new Date());
      //What the heck does this do? Is this just poorly named?
      //should it be .cacheUsers or something?
      userContext.actions.acceptUsers(users);
      return { rows: cash_outs, type: 'fetchForFirstLoad' };
    },

    search: async (text: string, userContext: any) => {
      const { cash_outs, users } = await AskAGirl.getCashOutsBefore(new Date(), text);
      userContext.actions.acceptUsers(users);
      return { rows: cash_outs, type: 'search' };
    },

    getById: async (id: string | null) => {
      if (id == null) return;
      const row = await AskAGirl.getCashOutById(id);
      return { rows: [row], type: 'getUsers' };
    },

};
