import AskAGirl from '../../api/AskAGirl';
import UserMonthlyConsumptionViewModel from '../../viewModels/UserMonthConsumptionViewModel';

export interface UserMonthlyConsumptionState {
  cached: { [id: string]: UserMonthlyConsumptionViewModel } //key is user_id
};

export default {

    initState: () => {
      return {
        cached: {},
      };
    },

    fetchForUserId: async (id: string) => {
      const { summaries } = await AskAGirl.getUserMonthlyConsumption(id);
      return { id, rows: summaries, type: 'fetchForUserId' };
    },

};
