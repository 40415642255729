import { FeedbackModel } from '../api/AskAGirl';
import formatDate from '../utilities/formatDate';

export default class FeedbackViewModel {

  model: FeedbackModel;

  constructor(model: FeedbackModel) {
    this.model = model;
  }

  // MARK: default

  get reported(): boolean { return this.model.reported }

  get reportedReason(): string { return this.model.report_reason }
  get details(): string { 
    return this.model.details || '(empty)';
  }
  get rating(): number { return this.model.rating }

  get reportedBy(): string { return this.model.feedbacker }


  get created(): Date { return new Date(this.model.created_at) }

  // MARK: custom

  get reportedAsString(): string { return this.model.reported ? 'Yes' : 'No' }

  get createdAsString(): string { return formatDate(this.created || new Date(0)); }

  get user_id(): string { return this.model.feedbackee.toString() };
  get id(): string { return this.model.id.toString() };

  setModel(model: FeedbackModel) {
    this.model = model;
  }

}
