import { UserState } from './usersActions';
import UserViewModel from '../../viewModels/UserViewModel';
import updateCache from '../../utilities/updateCache';

const cache = updateCache.bind(null, UserViewModel);

export default function (state: UserState, action: any): UserState {
  const newState = { ...state };
  switch (action.type) {
    case 'getUsers':
      action.rows.filter((row:any) => !!row).forEach((row:any) => cache(newState.cached, row));
      newState.total_count = action.total_count;
      break;
    case 'searchUsers':
      newState.rows = action.rows.map((m:any) => cache(newState.cached, m));
      newState.total_count = action.total_count;
      break;
    case 'startFetching':
      newState.isFetching = true;
      break;
    case 'stopFetching':
      newState.isFetching = false;
      break;
    case 'unbannedUser':
    case 'bannedUser':
      const id = Object
        .keys(newState.cached)
        .filter(m => newState.cached[m].id === action.banned_user_id)[0];
      if (id) {
        const method = 'unbannedUser' === action.type ? 'markUnbanned' : 'markBanned';
        newState.cached[id][method](action.duration || null);
      }
  }
  return newState;
}
