import React from 'react';
import AdvisorsMain from './advisorsMain';
import TabbedPage from '../TabbedPage/tabbedPage';

const tabs = [
  {
    title: 'Browse Advisors',
    id: 'main',
    component: () => <AdvisorsMain />
  }
];

export default () => {
  return (
    <div>
      <h1 className="main-pane-header">Advisors</h1>
      <TabbedPage tabs={tabs} defaultTab="main" />
    </div>
  );
}
