import React from 'react';
import { Link } from 'react-router-dom';
import { Ban, AdvisorModel, BanType } from '../api/AskAGirl';
import formatDate from '../utilities/formatDate';

export default class AdvisorViewModel {

  private model: AdvisorModel;

  constructor(model: AdvisorModel) {
    this.model = model;
  }

  // MARK: default

  get email(): string { return this.model.email_address }
  get location(): string { return this.model.location.name }
  get bio(): string { return this.model.about_me }
  get interests(): string { return this.model.interests.join(', ') }
  get gender(): string { return this.model.gender }
  get taxDocumentUrl(): string | null {
    return this.model.tax_document_url; 
  }
  get referrerId(): string | null {
    return this.model.referrer_id; 
  }

  get photos(): string[] { return this.model.profile_image_urls }
  get instagramUsername(): string | null { return this.model.instagram_username }
  get instagramUrl(): string | null { 
    if (null == this.instagramUsername) {
      return null;
    }
    return `https://instagram.com/${this.instagramUsername}`;
  }

  get created(): Date { return new Date(this.model.created_at) }
  get lastOnline(): String { 
    const date = new Date(this.model.last_online_at);
    if (null == date) {
      return 'error';
    }
    return (date as any).toString().match(/\w+ (\w+ \d+ \d+)/)[1] || '';
  }
  get name(): String { return this.model.full_name }
  get birthDate(): Date { return new Date(this.model.birth_date) }
  get nameLink(): any {
    return <Link to={`/users/main/actions/${this.id}`}>{this.name}</Link>;
  }
  get emailLink(): any {
    return <a href={`mailto:${this.email}`}>{this.email}</a>;
  }

  // MARK: custom
  
  get createdAsString(): string {
    const date = this.created || new Date(0);
    return (date as any).toString().match(/\w+ (\w+ \d+ \d+)/)[1] || '';
  }

  get age(): number {
    return Math.floor( (Date.now() - this.birthDate.getTime()) / (365 * 86400 * 1000) );
  }

  get id(): string { return this.model.id.toString() };

  get banned(): boolean {   
    return (null != this.model.ban);
  }

  get bannedUntil(): Date | null {
    if (null == this.model.ban || null == this.model.ban.expires_at) {
      return null;
    } else {
      return new Date(this.model.ban.expires_at);
    }
  }

  get permBanned(): Boolean {
    return null != this.model.ban && this.model.ban.type === BanType.permanent;
  }

  get bannedUntilAsString(): string {
    if (false === this.banned) {
      return 'not banned';
    }
    if (null == this.bannedUntil) {
      return 'banned forever';
    } else {
      return 'banned until ' + formatDate(this.bannedUntil);
    }
  }


  setModel(model: AdvisorModel) {
    this.model = model;
  }

  markBanned(until: Date | null) { 
    this.model.ban = new Ban(until);
  }

  markUnbanned() { 
    this.model.ban = null; 
  }

}
