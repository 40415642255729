import AskAGirl from '../../api/AskAGirl';
import UsersMonthConsumptionViewModel from '../../viewModels/UsersMonthConsumptionViewModel';

export interface TopClientsMonthlyConsumptionState {
  rows: UsersMonthConsumptionViewModel[],
  cached: { [id: string]: UsersMonthConsumptionViewModel }
};

export default {

    initState: () => {
      return {
        cached: {},
        rows: []
      };
    },

    fetchForFirstLoad: async (userContext: any) => {
      const { summaries, users } = await AskAGirl.getTopUsersMonthlyConsumption();
      userContext.actions.acceptUsers(users);
      return { rows: summaries, type: 'fetchForFirstLoad' };
    },

};
