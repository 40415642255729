import React from 'react';
import { Route, Link, useRouteMatch } from 'react-router-dom';
import AdvisorsProvider from '../../providers/Advisors/advisorsProvider';
import AdvisorViewModel from '../../viewModels/AdvisorViewModel';

type Props = { user: AdvisorViewModel };

export default ({ user }: Props) => {
  const mailto = 'mailto:' + user.email;
  const referrer_link = '/advisors/main/actions/' + user.referrerId;
  return <div>
    <div className="icon email row">
      <a href={mailto}>{user.email}</a>
    </div>
    <div className="icon cake row">
      {user.age} years old
    </div>
    <div className="icon location row">
      {user.location}
    </div>
    <div className="icon interests row">
      {user.interests}
    </div>
    {user.instagramUrl && 
      <div className="icon instagram-username row">
        <a href={user.instagramUrl} target="_blank" rel="noopener noreferrer">{user.instagramUsername}</a>
      </div>
    }
    {user.bio &&
      <div className="icon bio row">
        {user.bio}
      </div>
    }
    <div className="icon gender row">
      {user.gender}
    </div>
    {user.taxDocumentUrl &&
      <div className="icon interests row">
        <a href={user.taxDocumentUrl} target="_blank" rel="noopener noreferrer">View W9</a>
      </div>
    }
    {!user.taxDocumentUrl &&
      <div className="icon interests row">
        {user.name} has not filled out a W9, yet.
      </div>
    }
    {user.referrerId &&
      <div className="icon interests row">
        {user.name} was referred by <a href={referrer_link} target="_blank" rel="noopener noreferrer">Another Advisor</a>
      </div>
    }
    {!user.referrerId &&
      <div className="icon interests row">
        {user.name} was not referred by anybody.
      </div>
    }
  </div>;
};
