import AskAGirl from '../../api/AskAGirl';
import AdvisorApplicationsViewModel from '../../viewModels/AdvisorApplicationsViewModel';

export interface AdvisorApplicationsState {
  rows: AdvisorApplicationsViewModel[],
  cached: { [id: string]: AdvisorApplicationsViewModel },
  isFetching: boolean
};

export default {

    initState: () => {
      return {
        cached: {},
        rows: [],
        isFetching: false
      };
    },

    async fetchForFirstLoad(userContext: any) {
      (this as any).dispatch({ type: 'startFetching' });
      try {
        const { applications, applicants } = await AskAGirl.getMemberAdvisorApplications();
        userContext.actions.acceptUsers(applicants);
        (this as any).dispatch({ type: 'stopFetching' });
        return { rows: applications, type: 'fetchForFirstLoad' };
      } catch (e) {
        console.error(e);
      }
      (this as any).dispatch({ type: 'stopFetching' });
    },

    getById: async (id: string | null) => {
      if (id == null) return;
      //const row = await AskAGirl.getMemberAdvisorApplicationsById(id);
      //return { rows: [row], type: 'getUsers' };
    },

    approve: async (id: string, reason: string) => {
      const status = await AskAGirl.acceptApplication(id, reason);
      if (null == status || true !== status.success) {
        throw new Error('SERVER_FAILED');
      }
      return { row_id: id, type: 'approved' };
    },

    deny: async (id: string, reason: string) => {
      const status = await AskAGirl.denyApplication(id, reason);
      if (null == status || true !== status.success) {
        throw new Error('SERVER_FAILED');
      }
      return { row_id: id, type: 'denied' };
    }




};
