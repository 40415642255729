import React, { useContext } from 'react';
import { useLocation, Link } from 'react-router-dom';
import classNames from 'classnames';
import './mainSidebar.scss';
import PreferencesProvider from '../../providers/Preferences/preferencesProvider';

type SidebarOptionType = { path: string, name: string };

function SidebarOption({ path, name }: SidebarOptionType) {
  const location = useLocation();
  const active = location.pathname.toLowerCase().startsWith(path);
  const baseClass = name.replace(/[ ']/g, '-').toLowerCase();
  const className = classNames(baseClass, { active });
  return <ul className={className}>
    <Link to={path}>{name}</Link>
    <div className="prefetch">
      <div className="hover" />
      <div className="active" />
    </div>
  </ul>;
}

function MainSidebar() {

  const prefs = useContext(PreferencesProvider.context) as any;
  const className = classNames('main-sidebar', { hidden: !prefs.state.sidebar.visible });

  return (
    <div className={className}>
      <div className="logo">AskAGirl</div>
      <li className="options">
        <SidebarOption path="/users/main" name="Users" />
        <SidebarOption path="/advisors/main" name="Advisors" />
        <SidebarOption path="/applications/main" name="Applications" />
        <SidebarOption path="/feedback" name="Feedback" />
        <SidebarOption path="/transactions/credit-purchases" name="Transactions" />
        <SidebarOption path="/stats" name="Stats" />
      </li>
    </div>
  );
}

export default MainSidebar;
