import React, { useContext, useEffect, useState } from 'react';
import { Route, Link, useRouteMatch } from 'react-router-dom';
import AdvisorsProvider from '../../providers/Advisors/advisorsProvider';
import ConfigableTable from '../ConfigableTable/configableTable';
import UserCard from '../UserCard/userCard';
import MainInfo from '../UserCard/mainInfo';
import AdvisorViewModel from '../../viewModels/AdvisorViewModel';
import BanUser from '../UserCard/banUser';

const Popover = () => {
  console.log('popover!');
  const match = useRouteMatch();
  const id = match.params.id;
  const advisors = useContext(AdvisorsProvider.context) as any;
  //eslint-disable-next-line
  useEffect(() => advisors.actions.voidGetById(id), [id]);
  const model = advisors.state.cached[id];
  if (!model) return null;
  return <UserCard type="purple" title={model.name} images={model.photos}>
    {model.reported && !model.banned && <div className="report-reason">Reported for {model.reportedReason}</div>}
    {model.banned && <div className="report-reason">{model.bannedUntilAsString}</div>}
    <br />
    <MainInfo user={model} />
    <BanUser user={model} />
  </UserCard>;
};

const Actions = ({ data }: { data: AdvisorViewModel }) => {
  const match = useRouteMatch();
  return <Link to={`${match.path}/actions/${data.id}`}>
    <button className="std-button">View</button>
  </Link>;
};

const columns = {
  Name: 'name',
  Email: 'emailLink',
  Location: 'location',
  Created: 'createdAsString',
  'Last Online': 'lastOnline',
  key: 'id',
  Actions: Actions
};

const visibleColumns = [
  'Name', 'Email', 'Location', 'Created', 'Last Online', 'Actions'
];


export default () => {
  const [search, setSearch] = useState<string | null>(null);
  const advisors = useContext(AdvisorsProvider.context) as any;
  //eslint-disable-next-line
  useEffect(() => advisors.actions.voidSearchAdvisors(search), [search]);
  const match = useRouteMatch();
  return (
    <div>
      <div className="dashboard main-pane">
        <input className="search"
               placeholder="🕵️  Search advisors"
               onChange={e => setSearch(e.target.value)} />
        <span>Total Advisors: <b>{advisors.state.total_count}</b></span>
        <ConfigableTable
          columns={columns}
          visibleColumns={visibleColumns}
          rows={advisors.state.rows}
        />
        {advisors.state.isFetching && !advisors.state.rows.length && <div className="row-spinner"/>}
        {!advisors.state.isFetching && !advisors.state.rows.length && <p>No results</p>}
      </div>
      <Route path={`${match.path}/actions/:id`} component={Popover} />
    </div>
  );
}
