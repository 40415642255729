import { UserMonthlyConsumptionState } from './userMonthlyConsumptionActions';
import UserMonthConsumptionViewModel from '../../viewModels/UserMonthConsumptionViewModel';
import { UserMonthConsumptionModel } from '../../api/AskAGirl';

export default function (state: UserMonthlyConsumptionState, action: any): UserMonthlyConsumptionState {
  const newState = { ...state };
  switch (action.type) {
    case 'fetchForUserId':
      newState.cached[action.id] = action.rows.map((m: UserMonthConsumptionModel) => new UserMonthConsumptionViewModel(m));
      break;
  }
  return newState;
}
