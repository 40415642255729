import { ConsumptionSummaryModel } from '../api/AskAGirl';

const format_seconds = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  let remaining_seconds = seconds - (hours * 3600);
  const minutes = Math.floor(seconds / 60);
  remaining_seconds = seconds - (minutes * 60);
  const floored_seconds = Math.floor(remaining_seconds);
  return `${hours}h ${minutes}m ${floored_seconds}s`;
};

export default class ConsumptionSummaryViewModel {

  model: ConsumptionSummaryModel

  constructor(model: ConsumptionSummaryModel) {
    this.model = model;
  }

  // MARK: default

  get text_messages(): string {
    return this.model.text_messages.toLocaleString('en-US');
  }

  get audio_call_time(): string {
    return format_seconds(this.model.audio_call_seconds);
  }

  get video_call_time(): string {
    return format_seconds(this.model.video_call_seconds);
  }

  get credits(): string {
    return this.model.amount_credits.toLocaleString('en-US');
  }

  get user_id(): string { 
    return this.model.user_id.toString() 
  }

  setModel(model: ConsumptionSummaryModel) {
    this.model = model;
  }

}
