import './App.scss';

import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import MainSidebar from './components/MainSidebar/mainSidebar';
import MainNavbar from './components/MainNavbar/mainNavbar';
import PreferencesProvider from './providers/Preferences/preferencesProvider';
import UsersProvider from './providers/Users/usersProvider';
import AdvisorsProvider from './providers/Advisors/advisorsProvider';
import UserMonthlyConsumptionProvider from './providers/Transactions/userMonthlyConsumptionProvider';
import SelfUserProvider from './providers/SelfUser/selfUserProvider';
import FeedbackProvider from './providers/Feedback/feedbackProvider';
import AdvisorApplicationsProvider from './providers/AdvisorApplications/advisorApplicationsProvider';

import Dashboard from './components/Dashboard/dashboard';
import Transactions from './components/Transactions/transactions';
import Users from './components/Users/users';
import Advisors from './components/Advisors/advisors';
import AdvisorApplications from './components/AdvisorApplications/advisorApplications';
import LoginGuard from './components/Login/loginGuard';

function HomePage() {
  return <Redirect to="/users" />;
}

function SetupTransactions() {
  return <Transactions />;
}

function SetupAdvisors() {
  return <Advisors />;
};

function SetupStats() {
  return <div>Coming Soon!</div>
}

function SetupDashboard() {
  return <FeedbackProvider.Provider>
    <Dashboard />
  </FeedbackProvider.Provider>
}

function SetupAdvisorApplications() {
  return <AdvisorApplicationsProvider.Provider>
    <AdvisorApplications />
  </AdvisorApplicationsProvider.Provider>
}

function App() {
  return (
    <div className="App">
      <Router>
        <SelfUserProvider.Provider>
          <PreferencesProvider.Provider>
            <UsersProvider.Provider>
              <AdvisorsProvider.Provider>
                <UserMonthlyConsumptionProvider.Provider>
                  <LoginGuard>
                    <MainSidebar />
                    <div className="right-side">
                      <MainNavbar />
                      <Switch>
                        <Route exact path="/">
                          <HomePage />
                        </Route>
                        <Route path="/users">
                          <Users />
                        </Route>
                        <Route path="/advisors">
                          <SetupAdvisors />
                        </Route>
                        <Route path="/applications">
                          <SetupAdvisorApplications />
                        </Route>
                        <Route path="/feedback">
                          <SetupDashboard />
                        </Route>
                        <Route path="/transactions">
                          <SetupTransactions />
                        </Route>
                        <Route path="/stats">
                          <SetupStats />
                        </Route>
                      </Switch>
                    </div>
                  </LoginGuard>
                </UserMonthlyConsumptionProvider.Provider>
              </AdvisorsProvider.Provider>
            </UsersProvider.Provider>
          </PreferencesProvider.Provider>
        </SelfUserProvider.Provider>
      </Router>
    </div>
  );
}

export default App;
