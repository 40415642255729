import AskAGirl, { UserModel } from '../../api/AskAGirl';
import UserViewModel from '../../viewModels/UserViewModel';

export interface UserState {
  cached: { [id: string]: UserViewModel },
  rows: UserViewModel[],
  total_count: number,
  isFetching: boolean
};

export default {

    initState: () => {
      return {
        rows: [],
        cached: {},
        total_count: 0,
        isFetching: false
      };
    },

    getById: async (id: string | null) => {
      if (id == null) return;
      const row = await AskAGirl.getUserById(id);
      return { rows: [row], type: 'getUsers' };
    },

    async searchUsers (query?: string) {
      (this as any).dispatch({ type: 'startFetching' });
      let object;
      try {
        object = await AskAGirl.searchUsers(query);
      } catch (e) {
        object = {
        };
        console.error(e);
      }
      (this as any).dispatch({ type: 'stopFetching' });
      return { 
        rows: object.users,
        total_count: object.total_count,
        type: 'searchUsers',
      };
    },

    ban: async (banned_user_id: string, expires_at: Date | null): Promise<any> => {
      await AskAGirl.ban(banned_user_id, expires_at);
      return { ban: true, type: 'bannedUser', banned_user_id, expires_at }
    },

    unban: async (banned_user_id: string): Promise<any> => {
      await AskAGirl.unban(banned_user_id);
      return { type: 'unbannedUser', banned_user_id }
    },

    acceptUsers: async(users: UserModel[]) => {
      return { rows: users, type: 'getUsers' };
    }

};
