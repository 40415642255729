import React, { useContext } from 'react';
import PreferencesProvider from '../../providers/Preferences/preferencesProvider';
import SelfUserProvider from '../../providers/SelfUser/selfUserProvider';
import './mainNavbar.scss';

function logout() {
  localStorage.clear();
  window.location.reload();
}

function MainNavbar() {

  const prefsCtx = useContext(PreferencesProvider.context) as any;
  const selfUserCtx = useContext(SelfUserProvider.context) as any;

  function sidebarToggle(event: any) {
    event.preventDefault();
    prefsCtx.actions.sidebarToggle();
  }

  //linter says we need content here, so I put a non printing character lol
  return <div className="main-navbar">
    <a className="collapse" onClick={sidebarToggle} href="/">&zwnj;</a>
    {selfUserCtx.state.loggedIn && <a className="logout" href="/" onClick={logout}>Logout</a>}
  </div>;
}

export default MainNavbar;
