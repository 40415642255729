import AskAGirl from '../../api/AskAGirl';
import PurchaseViewModel from '../../viewModels/PurchaseViewModel';
import PurchaseCountsViewModel from '../../viewModels/PurchaseCountsViewModel';

export interface PurchaseState {
  rows: PurchaseViewModel[],
  counts: PurchaseCountsViewModel,
  cached: { [id: string]: PurchaseViewModel }
};

export default {

    initState: () => {
      return {
        cached: {},
        counts: {},
        rows: []
      };
    },

    fetchForFirstLoad: async (userContext: any) => {
      const { purchases, users, counts } = await AskAGirl.getPurchasesBefore(new Date());
      //What the heck does this do? Is this just poorly named?
      //should it be .cacheUsers or something?
      userContext.actions.acceptUsers(users);
      return { 
        rows: purchases, 
        counts,
        type: 'fetchForFirstLoad',
    };
    },

    search: async (text: string, userContext: any) => {
      const { purchases, users, counts } = await AskAGirl.getPurchasesBefore(new Date(), text);
      userContext.actions.acceptUsers(users);
      return { 
        rows: purchases, 
        counts,
        type: 'search',
      };
    },

    getById: async (id: string | null) => {
      if (id == null) return;
      const row = await AskAGirl.getPurchaseById(id);
      return { rows: [row], type: 'getUsers' };
    },

};
