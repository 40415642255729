import { UsersMonthConsumptionModel } from '../api/AskAGirl';
import ConsumptionSummaryViewModel from '../viewModels/ConsumptionSummaryViewModel';

export default class UsersMonthConsumptionViewModel {

  model: UsersMonthConsumptionModel

  constructor(model: UsersMonthConsumptionModel) {
    this.model = model;
  }

  // MARK: default

  get id(): string { 
    return this.model.id;
  }

  get year(): string {
    return String(this.model.year);
  }

  get month(): string {
    switch (this.model.month) {
    case 1:
      return 'January';
    case 2:
      return 'February';
    case 3:
      return 'March';
    case 4:
      return 'April';
    case 5:
      return 'May';
    case 6:
      return 'June';
    case 7:
      return 'July';
    case 8:
      return 'August';
    case 9:
      return 'September';
    case 10:
      return 'October';
    case 11:
      return 'November';
    case 12:
      return 'December';
    default: 
      return `Invalid Month: ${this.model.month}`;
    }
  }

  get summaries(): ConsumptionSummaryViewModel[] {
    return this.model.summaries.map(summary => {
      return new ConsumptionSummaryViewModel(summary);
    });
  }

  setModel(model: UsersMonthConsumptionModel) {
    this.model = model;
  }

}
