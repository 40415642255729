import { CashOutState } from './cashoutActions';
import CashOutViewModel from '../../viewModels/CashOutViewModel';
import { CashOutModel } from '../../api/AskAGirl';
import updateCache from '../../utilities/updateCache';

const cache = updateCache.bind(null, CashOutViewModel);

export default function (state: CashOutState, action: any): CashOutState {
  const newState = { ...state };
  switch (action.type) {
    case 'search':
    case 'fetchForFirstLoad':
      newState.rows = action.rows.map((m: CashOutModel) => cache(newState.cached, m));
      break;
    case 'getUsers':
      action.rows.forEach((m: CashOutModel) => cache(newState.cached, m));
      break;
  }
  return newState;
}
