import firebase from './firebase';
import constants from '../common/constants';

export interface ConsumptionSummaryModel {
  user_id: string,
  amount_credits: number,
  text_messages: number,
  audio_call_seconds: number,
  video_call_seconds: number
};

export interface UserMonthConsumptionModel {
  id: string,
  month: number,
  year: number,
  summary: ConsumptionSummaryModel
};

export interface UserMonthlyConsumptionModel {
  id: string, //this is the user id
  summaries: UserMonthConsumptionModel[]
};

export interface UsersMonthConsumptionModel {
  id: string,
  month: number,
  year: number,
  summaries: ConsumptionSummaryModel[]
};

export interface UsersMonthlyConsumptionModel {
  users: UserModel[],
  summaries: UsersMonthConsumptionModel[]
};

export interface FeedbackModel {
  created_at: string,
  details: string | null,
  feedbacker: string,
  feedbackee: string,
  id: string,
  rating: number,
  reported: boolean,
  report_reason: string
};

export interface PurchaseModel {
  id: string,
  user_id: string,
  product_id: string,
  purchased_at: Date
};

export interface PurchaseCountsModel {
  'com.askagirl.twenty_credits': number,
  'com.askagirl.fourty_credits': number,
  'com.askagirl.eighty_credits': number,
  'com.askagirl.one_hundred_credits': number,
  'com.askagirl.five_hundred_credits': number,
  'com.askagirl.one_thousand_credits': number,
};

export interface CashOutModel {
  id: string,
  user_id: string,
  status: string,
  amount_cents: number,
  created_at: string,
  ended_at: string | null,
};

interface Coordinate {
  longitude: Number,
  latitude: Number,
};

interface Location {
  id: string,
  name: string,
  coordinate: Coordinate,
};

export enum BanType {
  permanent = "permanent",
  temporary = "temporary",
}

export class Ban {

  type: BanType
  expires_at: Date | null

  constructor(expires_at: Date | null) {
    this.expires_at = expires_at;
    if (null == expires_at) {
      this.type = BanType.permanent;
    } else {
      this.type = BanType.temporary;
    }
  }

}

export interface AdvisorApplicationModel {
  id: string,
  user_id: string,
  government_id_front_image_url: string,
  government_id_back_image_url: string,
  profile_image_urls: string[],
  about_me: string,
  expertise: string[],
  created_at: string,
}

export interface UserModel {
  id: string,
  full_name: string,
  gender: string,
  created_at: string,
  last_online_at: string,
  birth_date: string,
  email_address: string,
  location: Location,
  interests: string[],
  about_me: string,
  profile_image_urls: string[],
  instagram_username: string | null,
  ban: Ban | null,
}

export interface UsersAndCount {
  users: UserModel[],
  total_count: number,
};

export interface AdvisorModel {
  id: string,
  full_name: string,
  gender: string,
  created_at: string,
  last_online_at: string,
  birth_date: string,
  email_address: string,
  location: Location,
  interests: string[],
  about_me: string,
  profile_image_urls: string[],
  instagram_username: string | null,
  ban: Ban | null,
  tax_document_url: string | null,
  referrer_id: string | null,
}

export interface AdvisorsAndCount {
  advisors: AdvisorModel[],
  total_count: number,
};

let jwt: string | null = null;

async function request(path: string, method: string, data: any = null): Promise<any> {
  if ('GET' === method && null != data) {
    // be nice
    const delim = constants.server.includes('?') ? '&' : '?';
    path += delim + Object.keys(data).reduce((h: any, k) => {
      if (undefined !== data[k]) h.push(`${k}=${encodeURIComponent(data[k])}`);
      return h;
    }, []).join('&');
  }
  let headers: any = {
    'Content-Type': 'application/json',
  };
  if (jwt) {
    headers['x-askagirl-token'] = jwt;
  }
  return fetch(constants.server + path, {
    method,
    headers,
    mode: 'cors',
    body: (data && 'GET' !== method) ? JSON.stringify(data) : null,
  })
  .then(response => response.json());
}

const get = async (path: string, data: any = null): Promise<any> => request(path, 'GET', data);
const post = async (path: string, data: any = null): Promise<any> => request(path, 'POST', data);
const del = async (path: string, data: any = null): Promise<any> => request(path, 'DELETE', data);


type FeedbackWithUsers = {
  users: UserModel[],
  feedback: FeedbackModel[]
};

type PurchaseWithUser = {
  user: UserModel,
  purchase: PurchaseModel
};

type PurchasesWithUsers = {
  users: UserModel[],
  counts: PurchaseCountsModel,
  purchases: PurchaseModel[]
};

type CashOutWithUser = {
  user: UserModel,
  cash_out: CashOutModel
};

type CashOutsWithUsers = {
  users: UserModel[],
  cash_outs: CashOutModel[]
};

type AdvisorApplicationsWithUsers = {
  applicants: UserModel[],
  applications: AdvisorApplicationModel[]
};

export default {

  getMemberAdvisorApplications: async (): Promise<AdvisorApplicationsWithUsers> => {
    const results = await get('/admin/pending-advisor-applications');
    return results;
  },

  getMemberFeedbacks: async (source: string, query?: string): Promise<FeedbackWithUsers> => {
    const results = await get('/admin/feedback', { query, source });
    return results;
  },

  getMemberFeedbackById: async (id: string): Promise<FeedbackModel> => {
    const results = await get(`/admin/feedback/${id}`);
    return results;
  },

  getCashOutsBefore: async (before: Date, query?: string): Promise<CashOutsWithUsers> => {
    const results = await get('/admin/cash-outs', {
      before: before.toISOString(),
      query,
    });
    return results;
  },

  getCashOutById: async (id: string): Promise<CashOutWithUser> => {
    const results = await get(`/admin/cash-outs/${id}`);
    return results;
  },

  getPurchasesBefore: async (before: Date, query?: string): Promise<PurchasesWithUsers> => {
    const results = await get('/admin/purchases', {
      before: before.toISOString(),
      query,
    });
    return results;
  },

  getPurchaseById: async (id: string): Promise<PurchaseWithUser> => {
    const results = await get(`/admin/purchases/${id}`);
    return results;
  },

  getUserMonthlyConsumption: async (id: string): Promise<UserMonthlyConsumptionModel> => {
    const results = await get(`/admin/users/${id}/monthly-consumption-summaries`);
    return results;
  },

  getTopUsersMonthlyConsumption: async (): Promise<UsersMonthlyConsumptionModel> => {
    const results = await get('/admin/top-users-monthly-consumption-summaries');
    return results;
  },

  getUserById: async (id: string): Promise<UserModel> => {
    const results = await get(`/admin/users/${id}`);
    return results.user;
  },

  getAdvisorById: async (id: string): Promise<AdvisorModel> => {
    const results = await get(`/admin/advisors/${id}`);
    return results.advisor;
  },

  searchUsers: async (query?: string): Promise<UsersAndCount> => {
    if (query == null || query === '') query = undefined;
    const results = await get('/admin/users', { query });
    return results;
  },

  searchAdvisors: async (query?: string): Promise<AdvisorsAndCount> => {
    if (query == null || query === '') query = undefined;
    const results = await get('/admin/advisors', { query });
    return results;
  },

  // returns a token
  login: async (username: string, password: string): Promise<string> => {
    await firebase.auth().signInWithEmailAndPassword(username, password);
    const token = await (firebase as any).auth().currentUser.getIdToken(true);
    try {
      const response = await post('/admin/firebase-id-tokens', { firebase_id_token: token });
      return response.jwt;
    } catch (error) {
      console.error('Login Error', error);
      throw new Error('Could not find your user.');
    }
  },

  ban: async (user_id: string, expires_at: Date | null): Promise<any> => {
    return await post('/admin/bans', { user_id, expires_at, });
  },

  unban: async (user_id: string): Promise<any> => {
    return await del(`/admin/users/${user_id}/active-bans`, { user_id });
  },

  acceptApplication: async (application_id: string, reason: string | null): Promise<any> => {
    return await post('/admin/advisor-application-decisions', {
      application_id,
      reason,
      accepted: true,
    });
  },

  denyApplication: async (application_id: string, reason: string | null): Promise<any> => {
    return await post('/admin/advisor-application-decisions', {
      application_id,
      reason,
      accepted: false,
    });
  },

  setJwt: (new_jwt: string) => {
    jwt = new_jwt;
  },

};
