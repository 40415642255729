import React, { useContext, useEffect, useState } from 'react';
import { Route, Link, useRouteMatch } from 'react-router-dom';
import UsersProvider from '../../providers/Users/usersProvider';
import UserMonthlyConsumptionProvider from '../../providers/Transactions/userMonthlyConsumptionProvider';
import ConfigableTable from '../ConfigableTable/configableTable';
import UserCard from '../UserCard/userCard';
import MainInfo from '../UserCard/mainInfo';
import UserViewModel from '../../viewModels/UserViewModel';
import BanUser from '../UserCard/banUser';

const consumptionColumns = {
  key: 'id',
  'Text Messages Sent': 'text_messages',
  'Audio Call Time': 'audio_call_time',
  'Video Call Time': 'video_call_time',
  'Credits Spent': 'credits',
};

const consumptionVisibleColumns = [
  'Text Messages Sent',
  'Audio Call Time',
  'Video Call Time',
  'Credits Spent',
];
const Popover = () => {
  const match = useRouteMatch();
  const id = match.params.id;
  const users = useContext(UsersProvider.context) as any;
  //eslint-disable-next-line
  useEffect(() => users.actions.voidGetById(id), [id]);
  const userConsumption = useContext(UserMonthlyConsumptionProvider.context) as any;
  //eslint-disable-next-line
  useEffect(() => userConsumption.actions.voidFetchForUserId(id), [id]);
  const model = users.state.cached[id];
  const consumptionModel = userConsumption.state.cached[id];
  if (!model) return null;
  if (!consumptionModel) return null;
  return <UserCard type="purple" title={model.name} images={model.photos}>
    {model.reported && !model.banned && <div className="report-reason">Reported for {model.reportedReason}</div>}
    {model.banned && <div className="report-reason">{model.bannedUntilAsString}</div>}
    <br />
    <MainInfo user={model} />
    <BanUser user={model} />
    <div className="user-monthly-consumptions">
      {consumptionModel.map((row: any) => {
        return (
          <div key={row.id} className="help-me">
            <h3>{row.month}, {row.year}</h3>
            <ConfigableTable 
              key={row.id}
              columns={consumptionColumns}
              visibleColumns={consumptionVisibleColumns}
              rows={[row]}
            />
          </div>
        );
      })}
    </div>
  </UserCard>;
};

const Actions = ({ data }: { data: UserViewModel }) => {
  const match = useRouteMatch();
  return <Link to={`${match.path}/actions/${data.id}`}>
    <button className="std-button">View</button>
  </Link>;
};

const columns = {
  Name: 'name',
  Email: 'emailLink',
  Location: 'location',
  Created: 'createdAsString',
  'Last Online': 'lastOnline',
  key: 'id',
  Actions: Actions
};

const visibleColumns = [
  'Name', 'Email', 'Location', 'Created', 'Last Online', 'Actions'
];


export default () => {
  const [search, setSearch] = useState<string | null>(null);
  const users = useContext(UsersProvider.context) as any;
  //eslint-disable-next-line
  useEffect(() => users.actions.voidSearchUsers(search), [search]);
  const match = useRouteMatch();
  return (
    <div>
      <div className="dashboard main-pane">
        <input className="search"
               placeholder="🕵️  Search users"
               onChange={e => setSearch(e.target.value)} />
        <span>Total Users: <b>{users.state.total_count}</b></span>
        <ConfigableTable
          columns={columns}
          visibleColumns={visibleColumns}
          rows={users.state.rows}
        />
        {users.state.isFetching && !users.state.rows.length && <div className="row-spinner"/>}
        {!users.state.isFetching && !users.state.rows.length && <p>No results</p>}
      </div>
      <Route path={`${match.path}/actions/:id`} component={Popover} />
    </div>
  );
}
