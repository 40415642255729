import { CashOutModel } from '../api/AskAGirl';
import formatDate from '../utilities/formatDate';

export default class CashOutViewModel {

  model: CashOutModel;

  constructor(model: CashOutModel) {
    this.model = model;
  }

  // MARK: default

  get amount(): string { 
    if (this.model.amount_cents < 100) {
      return `${this.model.amount_cents} cents`;
    }
    const dollars = this.model.amount_cents / 100;
    return new Intl.NumberFormat('en-US', { 
      style: 'currency', 
      currency: 'USD',
    }).format(dollars);
  }

  get status(): string {
    return this.model.status;
  }

  get created(): Date { 
    return new Date(this.model.created_at);
  }

  get ended(): Date | null { 
    if (null == this.model.ended_at) {
      return null;
    }
    return new Date(this.model.ended_at);
  }

  // MARK: custom

  get createdAsString(): string { return formatDate(this.created); }

  get endedAsString(): string { 
    if (null == this.ended) {
      return 'Still Processing';
    }
    return formatDate(this.ended); 
  }

  get user_id(): string { return this.model.user_id.toString() };
  get id(): string { return this.model.id.toString() };

  setModel(model: CashOutModel) {
    this.model = model;
  }

}
