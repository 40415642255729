import React, { useContext, useEffect, useState } from 'react';
import { Route, Link, useRouteMatch } from 'react-router-dom';
import UsersProvider from '../../providers/Users/usersProvider';
import AdvisorApplicationsProvider from '../../providers/AdvisorApplications/advisorApplicationsProvider';
import ConfigableTable from '../ConfigableTable/configableTable';
import UserCard from '../UserCard/userCard';
import MainInfo from '../UserCard/mainInfo';
import UserViewModel from '../../viewModels/UserViewModel';
import BanUser from '../UserCard/banUser';
import combine from '../../utilities/combine';
import './advisorApplications.scss';

const Popover = () => {
  const match = useRouteMatch();
  const id = match.params.id;
  const advisorApplications = useContext(AdvisorApplicationsProvider.context) as any;
  const users = useContext(UsersProvider.context) as any;
  const application = advisorApplications.state.cached[id];
  //eslint-disable-next-line
  useEffect(() => application ? users.actions.voidGetById(application.user_id) : undefined, [id, application]);
  if (!application) return null;
  const model = users.state.cached[application.user_id];
  if (!model) return null;
  const viewGovIDFront = () => window.open(application.govIdFront);
  const viewGovIDBack = () => window.open(application.govIdBack);
  return <UserCard type="purple" title={model.name} images={model.photos}>
    {model.reported && !model.banned && <div className="report-reason">Reported for {model.reportedReason}</div>}
    {model.banned && <div className="report-reason">Banned until {model.bannedUntilAsString}</div>}
    <br />
    <MainInfo user={model} />
    <div>
      <h4>Application Fields</h4>
      <div className="icon expertise row">
        {application.expertise}
      </div>
      <div className="icon about-me row">
        {application.aboutMe}
      </div>
      <div className="profile-images row">
        {application.profileImageUrls.map((url: string) => {
          return (
            <a href={url} target="_blank" rel="noopener noreferrer">
              <img alt="Profile" src={url} />
            </a>
          );
        })}
      </div>
    </div>
    <div className="buttons row">
      <button className="info" onClick={viewGovIDFront}>View Gov ID Front</button>
      <button className="info" onClick={viewGovIDBack}>View Gov ID Back</button>
    </div>
    <BanUser user={model} />
  </UserCard>;
};

const Actions = ({ data }: { data: UserViewModel }) => {
  const match = useRouteMatch();
  const advisorApplications = useContext(AdvisorApplicationsProvider.context) as any;
  const acceptApplication = async () => {
    let reason = prompt('Reason for your decision? You can leave this blank');
    if (null == reason || '' === reason.trim()) {
      reason = null;
    }
    await advisorApplications.actions.approve(data.id, reason);
  };
  const denyApplication = async () => {
    let reason = prompt('Reason for your decision? You can leave this blank');
    if (null == reason || '' === reason.trim()) {
      reason = null;
    }
    await advisorApplications.actions.deny(data.id, reason);
  };
  return <div className="advisor-actions">
      <Link to={`${match.path}/actions/${data.id}`}>
        <button className="std-button">View</button>
      </Link>
      <button className="std-button" onClick={acceptApplication}>Approve</button>
      <button className="std-button" onClick={denyApplication}>Deny</button>
    </div>;
};

const columns = {
  Name: 'name',
  Email: 'email',
  Location: 'location',
  Created: 'createdAsString',
  key: 'id',
  Actions: Actions
};

const visibleColumns = [
  'Name', 'Email', 'Location', 'Created', 'Actions'
];


export default () => {
  const [search, setSearch] = useState<string | null>(null);
  const advisorApplications = useContext(AdvisorApplicationsProvider.context) as any;
  const users = useContext(UsersProvider.context) as any;
  //eslint-disable-next-line
  useEffect(() => advisorApplications.actions.voidFetchForFirstLoad(users), []);
  let rows = combine(advisorApplications.state.rows, users.state.cached, 'user_id');
  rows = rows.filter((row:any) => !row.acceptedInSession && !row.deniedInSession);
  if ("" !== search && null != search) {
    rows = rows.filter((row:any) => row.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
  }
  const match = useRouteMatch();
  return (
    <div>
      <div className="dashboard main-pane">
        <input className="search"
               placeholder="🕵️  Search applications"
               onChange={e => setSearch(e.target.value)} />
        <ConfigableTable
          columns={columns}
          visibleColumns={visibleColumns}
          rows={rows}
        />
        {advisorApplications.state.isFetching && !rows.length && <div className="row-spinner"/>}
        {!advisorApplications.state.isFetching && !rows.length && <p>No results</p>}
      </div>
      <Route path={`${match.path}/actions/:id`} component={Popover} />
    </div>
  );
}
