import React, { useEffect } from 'react';
import classNames from 'classnames';
import './userCard.scss';
import { useHistory } from 'react-router-dom';

const goBack = (history: any, backUrl?: string) => {
  if (null != backUrl) {
    history.push(backUrl);
  } else {
    history.push(window.location.pathname.split('/actions/')[0]);
  }
}

type Props = { type: string, images: string[], title: string, children?: any, backUrl?: string };

export default ({ type, images, title, children, backUrl }: Props) => {
  const image_elements = [];
  for (let i = 0; i < images.length; i += 1) {
    const image = images[i];
    const alt = `profile image ${i}`;
    image_elements.push(
      <a href={image} target="_blank" rel="noopener noreferrer">
        <img className="small-image" src={image} alt={alt} /> 
      </a>
    );
  }
  const history = useHistory();
  useEffect(() => {
    document.body.classList.add('fullscreen-content');
    return () => document.body.classList.remove('fullscreen-content');
  }, []);
  const className = classNames('user-card', type);
  const color = classNames('color', type);
  const backgroundImage = {
    backgroundImage: `url(${images[0]})`
  };
  return (
    <div className="user-card-container">
      <div className={className}>
        <div className={color}>
          <div className="image" style={backgroundImage} />
        </div>
        <h1>{title}</h1>
        {image_elements}
        <div className="close" onClick={() => goBack(history, backUrl)}>x</div>
        <div className="body">
          {children}
        </div>
      </div>
    </div>
  );
};
