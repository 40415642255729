import { TopClientsMonthlyConsumptionState } from './topClientsMonthlyConsumptionActions';
import UsersMonthConsumptionViewModel from '../../viewModels/UsersMonthConsumptionViewModel';
import { UsersMonthConsumptionModel } from '../../api/AskAGirl';
import updateCache from '../../utilities/updateCache';

const cache = updateCache.bind(null, UsersMonthConsumptionViewModel);

export default function (state: TopClientsMonthlyConsumptionState, action: any): TopClientsMonthlyConsumptionState {
  const newState = { ...state };
  switch (action.type) {
    case 'fetchForFirstLoad':
      newState.rows = action.rows.map((m: UsersMonthConsumptionModel) => cache(newState.cached, m));
      break;
  }
  return newState;
}
