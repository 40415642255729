import { PurchaseModel } from '../api/AskAGirl';
import formatDate from '../utilities/formatDate';

export default class PurchaseViewModel {

  model: PurchaseModel;

  constructor(model: PurchaseModel) {
    this.model = model;
  }

  // MARK: default

  get credits(): number { 
    switch (this.model.product_id) {
    case 'com.askagirl.twenty_credits':
      return 20;
    case 'com.askagirl.fourty_credits':
      return 40;
    case 'com.askagirl.eighty_credits':
      return 80;
    case 'com.askagirl.one_hundred_credits':
      return 100;
    case 'com.askagirl.five_hundred_credits':
      return 500;
    case 'com.askagirl.one_thousand_credits':
      return 1000;
    default:
      return 0;
    }
  }

  get price(): string {
    switch (this.model.product_id) {
    case 'com.askagirl.twenty_credits':
      return '$4.99';
    case 'com.askagirl.fourty_credits':
      return '$9.99';
    case 'com.askagirl.eighty_credits':
      return '$19.99';
    case 'com.askagirl.one_hundred_credits':
      return '$24.99';
    case 'com.askagirl.five_hundred_credits':
      return '$124.99';
    case 'com.askagirl.one_thousand_credits':
      return '$249.99';
    default:
      return '$0.00';
    }
  }

  // MARK: custom

  get purchasedAtAsString(): string { 
    return formatDate(new Date(this.model.purchased_at)); 
  }

  get user_id(): string { return this.model.user_id.toString() };
  get id(): string { return this.model.id.toString() };

  setModel(model: PurchaseModel) {
    this.model = model;
  }

}
