import React from 'react';
import { Link, Route, useRouteMatch } from 'react-router-dom';
import './tabbedPage.scss';

interface Tab {
  title: string,
  id: string,
  component: any,
};

type RoutedTabsProps = { tabs: Tab[], defaultTab?: string };

const Header = ({ tabs, defaultTab }: RoutedTabsProps) => {
  const match = useRouteMatch();
  return (
    <div className="header-container">
      {tabs.map(tab => {
        const path = `${match.path}/${tab.id}`;
        const header = () => <h1>{tab.title}</h1>;
        return <div key={path}>
          {defaultTab === tab.id && <Route exact key={match.path} path={match.path} component={header} />}
          <Route key={path} path={[path]} component={header} />
        </div>
      })}
    </div>
  );
};

const RoutedTabs = ({ tabs, defaultTab }: RoutedTabsProps) => {
  const match = useRouteMatch();
  return (
    <div className="display-tab">
      {tabs.map(tab => {
        const path = `${match.path}/${tab.id}`;
        return <div key={path}>
          {defaultTab === tab.id && <Route exact key={match.path} path={match.path} component={tab.component} />}
          <Route key={path} path={[path]} component={tab.component} />
        </div>
      })}
    </div>
  );
};

type Props = { tabs: Tab[], defaultTab?: string };

export default ({ tabs, defaultTab }: Props) => {
  const match = useRouteMatch();
  return (
    <div className="tabbed-page">
      <Header tabs={tabs} defaultTab={defaultTab} />
      <div className="tab-names">
        {tabs.map(tab => {
          let tabMatch = useRouteMatch(`${match.path}/${tab.id}`);
          if (!tabMatch && defaultTab === tab.id) {
            const parentMatch = useRouteMatch(match.path);
            tabMatch = parentMatch && parentMatch.isExact;
          }
          const c = tabMatch ? 'active' : null;
          const to = `${match.url}/${tab.id}`;
          return <Link key={to} to={to} className={c}>{tab.title}</Link>
        })}
      </div>
      <RoutedTabs tabs={tabs} defaultTab={defaultTab} />
    </div>
  );
};
