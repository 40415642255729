import React, { useReducer } from 'react';

// check if string starts with word "void", returns that as boolean,
// then strips word void if it exists and returns rest of string
// keeing camelCase. For example if I call this method with
// "someThing" it returns [false, "someThing"] but if I
// call it with "voidSomeThing" it returns
// [true, "someThing"]

function checkForVoid(str: string): [boolean, string] {
  let isVoid = false;
  if (str.startsWith('void')) {
    isVoid = true;
    str = str[4].toLowerCase() + str.slice(5);
  }
  return [isVoid, str];
}

export default function createProvider(actions: any, reducer: any) {

  const context = React.createContext({} as any);
  const initialData = actions.initState() || {};
  const initialState = reducer(initialData, { type: 'initState' });

  return {
    context,
    Provider: function ({ children }: { children: any }) {
      const [state, dispatch] = useReducer(reducer, initialState);
      // whatever an action method returns also gets dispatched, but we 
      // also decorate the actions object with dispatch so an
      // action may dispatch multiple things if they need
      actions.dispatch = dispatch;
      // we need a wraper around actions because each action needs to call
      // dispatch(return value from the action function that got called)
      // also lets you tack word "void" onto the front of a fn call
      // and that causes the action to have a return type of void
      // instead of Promise. Useful for passing action fns
      // directly into useEffect, which cannot accept
      // promise return types.
      const action = new Proxy({}, {
        get: function(target: any, prop: string, receiver: any) {
          const [isVoid, method] = checkForVoid(prop);
          const actionWrapped = async function(...args: any[]) {
            const data = await actions[method](...args);
            if (data) (dispatch as any)(data);
          }
          return isVoid ? (...args: any[]) => { actionWrapped(...args) } : actionWrapped;
        }
      });
      return (
        <context.Provider value={{ state, dispatch, actions: action }}>
          {children}
        </context.Provider>
      );
    },
  };

}
