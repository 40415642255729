import { PurchaseState } from './purchaseActions';
import PurchaseViewModel from '../../viewModels/PurchaseViewModel';
import { PurchaseModel } from '../../api/AskAGirl';
import PurchaseCountsViewModel from '../../viewModels/PurchaseCountsViewModel';
import updateCache from '../../utilities/updateCache';

const cache = updateCache.bind(null, PurchaseViewModel);

export default function (state: PurchaseState, action: any): PurchaseState {
  const newState = { ...state };
  switch (action.type) {
    case 'search':
    case 'fetchForFirstLoad':
      newState.rows = action.rows.map((m: PurchaseModel) => cache(newState.cached, m));
      newState.counts = updateCache(PurchaseCountsViewModel, newState.cached, action.counts);
      break;
    case 'getUsers':
      action.rows.forEach((m: PurchaseModel) => cache(newState.cached, m));
      break;
  }
  return newState;
}
